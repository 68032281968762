export const keyAdmin = "admin";
export const keyPermission = "group";
export const keyBranch = "branch";
export const keyCharity = "charity";
export const keyCombo = "product_combo";
export const keyCoupon = "coupon";
export const keyFlashSale = "flash_sale";
export const keyNews = "news";
export const keyNewsCategory = "news_category";
export const keyOrders = "order";
export const keyOrdersFail = "order_fail";
export const keyProductCategory = "product_category";
export const keyProductReview = "product_review";
export const keyProduct = "product";
export const keyPromotion = "slider";
export const keyStaticPage = "page";
export const keyStore = "store";
export const keyUserContact = "contact";
export const keyEmailRegister = "subscriber";
export const keyUserProfile = "customer";
export const keyWarehouse = "inventory";
export const keyWarehouseReceipt = "inventory_receipt";
export const keyCouponLoyalty = "coupon_loyalty";
export const keyRankManagement = "rank_management";
export const keyCouponSharing = "coupon_sharing";